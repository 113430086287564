/**
 * Toggle menu
 */
const menu = () => {
  let toggle = document.querySelector('.js-toggle')
  let menu = document.querySelector('.js-menu')

  if(toggle && menu) {
    toggle.addEventListener('click', function() {
      toggle?.classList.toggle('active')
      menu?.classList.toggle('active')
    })
  }
}

export default menu