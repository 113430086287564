/**
 * Toggle header
 */
const header = () => {
  let header = document.querySelector('header')

  function toggleDisplay() {
    if (window.scrollY > 0) {
      if(!header?.classList.contains('scrolled')) {
        header?.classList.add('hide')
        setTimeout(function() {
          header?.classList.add('scrolled')
        }, 300)
        setTimeout(function() {
          header?.classList.remove('hide')
        }, 400)
      }
    } else {
      if(header?.classList.contains('scrolled')) {
        header?.classList.add('hide')
        setTimeout(function() {
          header?.classList.remove('scrolled')
        }, 300)
        setTimeout(function() {
          header?.classList.remove('hide')
        }, 400)
      }
    }
  }

  window.addEventListener('scroll', function() {
    toggleDisplay()
  })
}

export default header