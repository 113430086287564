import anima from './components/anima'
import header from './components/header'
import menu from './components/menu'

document.addEventListener(
  'DOMContentLoaded',
  () => {
    header()
    menu()
    anima()
  },
  false
)
